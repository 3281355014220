// -> import default controller
import Controller from './Controller';

class MagazineController extends Controller {
  // set constants
  _model = 'magazine';
  _loading = 'loading';
  _url = 'https://magazine.zsix.co/api';

  get(id) {
    const url = this._url + '/magazines/' + id;
    this.dispatch(this._loading, 'loading', true);

    this.request('get', url).then(({data}) => {
      this.dispatch(this._model, {
        'magazine': data,
        'pages': data.pdfs
      })
    }).finally(() => this.dispatch(this._loading, 'loading', false));
  }

  getBanner() {
    const url = this._url + '/banners';
    this.dispatch(this._loading, 'loading', true);

    this.request('get', url)
      .then((data) => {
        this.dispatch(this._model, 'banner', data.data);
      })
      .finally(() => this.dispatch(this._loading, 'loading', false));
  }

  getAll() {
    const url = this._url + '/magazines';
    this.dispatch(this._loading, 'loading', true);

    this.request('get', url)
      .then(({data}) => {
        this.dispatch(this._model, 'magazines', data.data);
      })
      .finally(() => this.dispatch(this._loading, 'loading', false));
  }

  getAllHighlights() {
    const url = this._url + '/magazines/highlights';
    this.dispatch(this._loading, 'loading', true);

    this.request('get', url)
      .then(({data}) => {
        this.dispatch(this._model, 'magazinesHighlights', data);
      })
      .finally(() => this.dispatch(this._loading, 'loading', false));
  }

  getRecommended(){
    const url = this._url + '/magazines/recommended';
    this.dispatch(this._loading, 'loading', true);

    this.request('get', url)
      .then(({data}) => {
        this.dispatch(this._model, 'magazinesRecommended', data);
      })
      .finally(() => this.dispatch(this._loading, 'loading', false));
  }
}

export default MagazineController;
