import Vue from 'vue';
import VueRouter from 'vue-router';
import {PROJECT_NAME} from '@/config/Settings';

// -> import views
import ViewController from '@/Controllers/ViewController';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: ViewController.load('Home'),
    meta: {
      KeepAlive: false, // Need to be cached?
      requiresAuth: false // Need to be logged
    }
  },
  {
    path: '/exame',
    name: 'Exame',
    component: ViewController.load('Home'),
    meta: {
      KeepAlive: false, // Need to be cached?
      requiresAuth: false // Need to be logged
    }
  },
  {
    path: '/globo',
    name: 'Globo',
    component: ViewController.load('Home'),
    meta: {
      KeepAlive: false, // Need to be cached?
      requiresAuth: false // Need to be logged
    }
  },



  {
    path: '/minhas-revistas',
    name: 'Books',
    component: ViewController.load('Books'),
    meta: {
      KeepAlive: false, // Need to be cached?
      requiresAuth: false // Need to be logged
    }
  },

  {
    path: '/revista/:id/:slug?',
    name: 'Magazine',
    component: ViewController.load('Magazine'),
    meta: {
      KeepAlive: false, // Need to be cached?
      requiresAuth: false // Need to be logged
    }
  },

  {
    path: '/categoria/:id/:slug?',
    name: 'Category',
    component: ViewController.load('Category'),
    meta: {
      KeepAlive: false, // Need to be cached?
      requiresAuth: false // Need to be logged
    }
  },

  {
    path: '/pesquisa',
    name: 'Search',
    component: ViewController.load('Search'),
    meta: {
      KeepAlive: false, // Need to be cached?
      requiresAuth: false // Need to be logged
    }
  },

  {
    path: '/faq',
    name: 'Faq',
    component: ViewController.load('Faq'),
    meta: {
      KeepAlive: false, // Need to be cached?
      requiresAuth: false // Need to be logged
    }
  },

  {
    path: '/termos-e-condicoes',
    name: 'Terms',
    component: ViewController.load('Terms'),
    meta: {
      KeepAlive: false, // Need to be cached?
      requiresAuth: false // Need to be logged
    }
  },

  {
    path: '/usuario',
    name: 'User',
    component: ViewController.load('User'),
    meta: {
      KeepAlive: false, // Need to be cached?
      requiresAuth: false // Need to be logged
    },
    redirect: '/usuario/conta',
    children: [
      {
        name: 'UserAccount',
        path: 'conta',
        component: ViewController.load('User/Account')
      }
    ]
  },

  // FIX THIS USING ROUTERVIEW
  {
    path: '/login',
    name: 'Auth',
    component: ViewController.load('Auth'),
    meta: {
      KeepAlive: false, // Need to be cached?
      requiresAuth: false
    }
  },
  {
    name: 'AuthMethod',
    path: '/login/metodo',
    component: ViewController.load('Auth/Method'),
    meta: {
      KeepAlive: false, // Need to be cached?
      requiresAuth: false
    }
  },
  {
    name: 'AuthPin',
    path: '/login/pin/:token',
    component: ViewController.load('Auth/Pin'),
    meta: {
      KeepAlive: false, // Need to be cached?
      requiresAuth: false
    }
  },
  {
    name: 'AuthRegister',
    path: '/login/registro',
    component: ViewController.load('Auth/Register'),
    meta: {
      KeepAlive: false, // Need to be cached?
      requiresAuth: false
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: '_is:active',
  linkExactActiveClass: '_is:exact-active',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {

  if (to.path !== '/' && from.path == to.path) {
    return;
  }

  const {token} = _.model('auth');

  document.title = PROJECT_NAME;

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!token) {
      // next({name: 'Auth'});
      next();
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
